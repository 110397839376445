<template>
  <PageWrapper>
    <div class="w-full flex flex-col" v-if="resident">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">
          Interview: {{ resident.firstName }} {{ resident.lastName }}
        </h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>
      <div class="w-full grid grid-cols-1" v-if="questions">
        <Question
          v-for="question in questions"
          @answerQuestion="answerQuestion"
          :questionGuid="question.questionGuid"
          :key="question.questionId"
          :questionText="question.questionText"
          :availableAnswers="question.availableAnswers"
          :controlType="question.controlType"
          :questionScope="question.questionScope"
          :level="question.level"
          :answer="question.answer"
        />
      </div>
      <div class="w-full flex justify-end items-center mt-4">
        <router-link
          :to="
            parseInt($route.params.id) === 1
              ? { name: 'interview' }
              : {
                  name: 'resident-interview',
                  params: { id: parseInt($route.params.id) - 1 },
                }
          "
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="
            parseInt($route.params.id) === residents.length
              ? { name: 'income' }
              : {
                  name: 'resident-interview',
                  params: { id: parseInt($route.params.id) + 1 },
                }
          "
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
    <div v-else>Loading...</div>
  </PageWrapper>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, watchEffect } from "vue";
import {
  findQuestionRecursively,
  getQuestionsRecursively,
} from "../../utils/functions";
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import Question from "../../components/home/ui/Question.vue";
import Header from "../../components/home/ui/Header.vue";
export default {
  components: {
    PageWrapper,
    Question,
    Header,
  },
  setup() {
    const route = useRoute();
    const { residents, determineEligibility } = useBenefitRecord();
    const resident = computed(() => residents.value[route.params.id - 1]);
    const questions = computed(() =>
      getQuestionsRecursively(0, resident.value.questionsAnswers)
    );
    const answerQuestion = async (questionGuid, answer) => {
      const question = findQuestionRecursively(questionGuid, questions.value);
      if (question) {
        question.answer = answer;
        await determineEligibility();
      }
    };

    watchEffect(() => {
      if (route.params.id) {
        const pageWapper = document.getElementById("page-wrapper");
        if (pageWapper) {
          document.getElementById("page-wrapper").scrollTop = 0;
        }
      }
    });

    return {
      resident,
      residents,
      questions,
      answerQuestion,
    };
  },
};
</script>

<style></style>
